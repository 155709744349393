import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RaffleStateDefault } from "../states/raffleState";
import { IRaffle } from "../interfaces/IRaffle";

const raffleSlice = createSlice({
    name: 'raffle',
    initialState: RaffleStateDefault,
    reducers: {
        saveActiveRaffleAction: (state, { payload }: PayloadAction<IRaffle | null>) => {
          return {
            ...state,
            ...payload,
          };
        },
    }
})

export const { saveActiveRaffleAction } = raffleSlice.actions;

export default raffleSlice.reducer;