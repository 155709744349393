export const PAYMENT_METHODS = [
    // {
    //     "id": "mercadopago",
    //     "name": "Mercado Pago"
    // },
    // {
    //     "id": "paypal",
    //     "name": "Paypal"
    // },
    {
        "id": "transfer",
        "name": "Transferencia"
    },
    {
        "id": "cash",
        "name": "Efectivo"
    },
    // {
    //     "id": "transbank",
    //     "name": "Transbank"
    // }
]
