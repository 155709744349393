import React from 'react';
import { Container, Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const NotFoundPage = () => (
  <Container maxWidth="md">
    <Box
      minHeight="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Typography variant="h1" color="primary" gutterBottom>
        404
      </Typography>
      <Typography variant="h5" color="textSecondary" gutterBottom>
        Oops! La página no existe
      </Typography>
      <Typography variant="body1" color="textSecondary" gutterBottom>
      La página a la que intentas acceder no existe no fue removida.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/"
        size="large"
      >
        Ir a inicio
      </Button>
    </Box>
  </Container>
);

export default NotFoundPage;
