import { PAYMENT_METHODS_ICONS } from "../../../../constants/icons";
import { CreateStepperRaffleProps } from "../../../../domain/CreateStepperRaffleProps";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Stack,
} from "@mui/material";
import BankAccounts, { BankAccount } from "../../bankAccounts";

export const SummaryRaffleStep = ({ resource }: CreateStepperRaffleProps) => {
  return (
    <Box paddingTop={2}>
      <Box mt={2}>
        <Typography>
          <strong>Nombre:</strong> {resource.name}
        </Typography>
        <Typography>
          <strong>Cantidad de tickets:</strong> {resource.limit}
        </Typography>
        <Typography>
          <strong>Precio del ticket:</strong> ${resource.ticketPrice}
        </Typography>
        <Typography>
          <strong>Tipo de concurso:</strong>{" "}
          {resource.type === "raffle" ? "Sorteo" : "Loteria"}
        </Typography>
      </Box>

      <Box mt={4}>
        <Typography variant="h6">Premios:</Typography>
        <Grid container>
          {resource.awards.map((award, index) => (
            <Grid item xs={12} xl={6} lg={6} md={12} sm={12} px={2} key={index}>
              <Card
                variant="outlined"
                sx={{ mt: 2, boxShadow: " 5px 5px 6px 2px" }}
              >
                <CardContent>
                  <Typography variant="h6">Premio {index + 1}</Typography>
                  <Typography>
                    <strong>Nombre:</strong> {award.name}
                  </Typography>
                  <Typography>
                    <strong>Caracteristicas:</strong>{" "}
                    {award.features.join(", ")}
                  </Typography>
                  {award.images.length > 0 && (
                    <Box>
                      <Typography variant="subtitle2">Imagenes:</Typography>
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        flexWrap="wrap"
                      >
                        {award.images.map((image, imgIndex) => (
                          <CardMedia
                            key={imgIndex}
                            component="img"
                            src={URL.createObjectURL(image)}
                            alt={`Award ${index + 1} Image ${imgIndex + 1}`}
                            sx={{ maxWidth: 200, maxHeight: 200, mr: 1, mt: 2 }}
                          />
                        ))}
                      </Box>
                    </Box>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box mt={4}>
      <Typography mb={2} fontWeight={"bold"} variant="h6">Cuentas bancarias</Typography>
        {
          resource.bankAccounts.map(bankAccount => (
            <BankAccount item={bankAccount} ticketSelectedCount={1}></BankAccount>
          ))
        }
      </Box>

      <Box mt={4}>
        <Typography variant="h6">Métodos de pago disponibles:</Typography>
        <Stack direction="row" spacing={2} display={"flex"} justifyContent={"center"}>
          {resource.availablePaymentMethods.map((method, index) => (
            <Box key={index}>
              <Card>
              <CardContent>
                {
                  PAYMENT_METHODS_ICONS("40px").find(
                    (icon) => icon.id === method
                  )?.icon
                }
              </CardContent>
            </Card>
            </Box>
          ))}
        </Stack>
      </Box>
    </Box>
  );
};
