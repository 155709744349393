import { Box, Button, Grid, Typography } from "@mui/material";
import { IRaffle } from "../../store/interfaces/IRaffle";

import { useIsMobileView } from "../../utils/responsive";
import { useAppSelector } from "../../store/hooks/useAppSelector";
import { RootState } from "../../store/types/rootState";
import { DoneAll } from "@mui/icons-material";

interface SuccessPurchaseProps {
  activeRaffle: IRaffle;
}

export const SuccessPurchase = ({ activeRaffle }: SuccessPurchaseProps) => {

  const isMobile = useIsMobileView();
  const accountInformation = useAppSelector(({ account }: RootState) => account);
  return (
    <Grid container sx={{ background: '#000' }}>
      <Grid item xs={12} xl={12} lg={12} md={12} sm={12} padding={isMobile ? 0 : 2}>
        <Grid item  xs={12} xl={12} lg={12} md={12} sm={12} padding={isMobile ? 0 : 1}>
          <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                <Typography
                  variant="h6"
                  component="div"
                  textAlign={"center"}
                >
                  <img
                    src={accountInformation.logo}
                    width={200}
                    height={200}
                    alt=""
                  />
                </Typography>
            </Grid>
            <Typography variant="h2" fontWeight={"bold"} textAlign={"center"}>
              {activeRaffle.name}
            </Typography>
        </Grid>
        <Grid item  xs={12} xl={12} lg={12} sm={12} paddingTop={2}>
        <Box mb={20} px={10}>
              <Typography textAlign={"center"} variant="h6">
                  Se ha creado la orden de compra, sus números llegarán a su correo una vez validemos la transferencia.
              </Typography>
              <Box display={"flex"} justifyContent={"center"}>
                <DoneAll sx={{ fontSize: 60 }} color="success"></DoneAll>
              </Box>
              <Box display={"flex"} justifyContent={"center"} mt={5}>
                <Button variant="contained" href="/">
                  VOLVER A COMPRAR
                </Button>
              </Box>
            </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
