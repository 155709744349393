import React, { useEffect, useState } from "react";
import { useGetPurchasersQuery, useGetRafflesQuery } from "../../store/api";
import { Box, SelectChangeEvent, Typography } from "@mui/material";
import { RaffleSelector } from "../raffle/utils/raffleSelector";
import { useIsMobileView } from "../../utils/responsive";
import { PurchasersTable } from "../puchasers/table";

export const PurchasersPage = () => {
  const [selectedRaffleId, setSelectedRaffleId] = useState<string>('');


  const { data: rafflesFounded, error: errorGetRaffles } = useGetRafflesQuery(undefined, {});

  const handleFindRaffleStatus = (e: SelectChangeEvent) => {
    const selectedValue = e.target.value;
    setSelectedRaffleId(selectedValue);
  };

  const { data: purchasers, refetch } = useGetPurchasersQuery(selectedRaffleId, {
    skip: selectedRaffleId === '',
  });

  useEffect(() => {
    if (selectedRaffleId !== "") {
      refetch();
    }
  }, [selectedRaffleId, refetch])
  

  const isMobileView = useIsMobileView();

  return (
    <Box paddingX={isMobileView ? 2 : 10} marginTop={2}>
      <Typography variant="h6" fontFamily={"Georgia"} paddingBottom={2} >
        Compradores
      </Typography>
      {rafflesFounded && !errorGetRaffles && rafflesFounded.length > 0 && (
        <RaffleSelector
          handleSelectRaffle={handleFindRaffleStatus}
          rafflesFounded={rafflesFounded}
          selectedRaffleId={selectedRaffleId}
          setSelectedRaffleId={setSelectedRaffleId}
        />
      )}

      {purchasers && selectedRaffleId ? <PurchasersTable raffleId={selectedRaffleId} purchasers={purchasers} /> : null}
    </Box>
  );
};
