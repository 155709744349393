import { Avatar, Box, Button, Card, CardContent, CardHeader, Chip, Container, FormControl, Grid, Paper, SelectChangeEvent, Stack, TextField, Typography } from "@mui/material";
import FaceIcon from '@mui/icons-material/Face';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import TrafficIcon from '@mui/icons-material/Traffic';
import { useState } from "react";
import { IRaffle } from "../../store/interfaces/IRaffle";
import { useGetTicketsByPurchaserEmailQuery, useLazyGetTicketsByPurchaserEmailQuery } from "../../store/api";
import { GeneratedTickets } from "../raffle/tickets/generatedTickets";
import { useAppSelector } from "../../store/hooks/useAppSelector";
import { RootState } from "../../store/types/rootState";


interface TicketsFinderProps {
    raffleId: string;
    activeRaffleName: string;
}

export const TicketsFinder = ({raffleId, activeRaffleName}: TicketsFinderProps) => {

    const [selectedEmailForSearch, setSelectedEmailForSearch] = useState<string>("");
    const accountInformation = useAppSelector(({ account }: RootState) => account);
    const [findTicketsByEmail, { data: ticketsFounded, isLoading }] = useLazyGetTicketsByPurchaserEmailQuery();
  
return (
    <Container>
        <Grid container>

        <Grid item xl={12} lg={12} md={12} xs={12} sm={12} mt={5}>
                <Typography
                  variant="h6"
                  component="div"
                  textAlign={"center"}
                  >
                  <img
                    src={accountInformation.logo}
                    width={200}
                    height={200}
                    style={{ borderRadius: 20 }}
                    alt=""
                    />
                </Typography>
            </Grid>
            <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                <Typography variant="h2" fontWeight={"bold"} textAlign={"center"}>
                    {activeRaffleName}
                    </Typography>
            </Grid>
        </Grid>

        <Box mt={2} width={'100%'}>
                    <FormControl fullWidth>
                    <TextField
                        label="Ingrese email para la consulta de sus tickets"
                        variant="outlined"
                        required
                        fullWidth
                        name="ticketPriceUSD"
                        value={selectedEmailForSearch}
                        onChange={(event) => setSelectedEmailForSearch(event.target.value)}
                        id="filled-adornment-amount"
                    />
                    </FormControl>
                </Box>
                <Stack>
                <Button disabled={selectedEmailForSearch === ''} sx={{ mt: 2}} variant="contained" onClick={() => findTicketsByEmail({
            email: selectedEmailForSearch,
            raffleId
        })}>
            Buscar mis tickets
        </Button>
                </Stack>
          {ticketsFounded && !isLoading && (
            <Box display={"flex"} justifyContent={"center"} width={"100%"}>
                <GeneratedTickets generatedTickets={ticketsFounded}></GeneratedTickets>
            </Box>
        )}

    </Container>
    
)
};

export default TicketsFinder;
