import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ITopPurchaser } from "../../store/interfaces/ITopPurchaser";
import { useGetTicketsByPurchaserEmailQuery, useSendEmailPurchaserMutation } from "../../store/api";
import { GeneratedTickets } from "../raffle/tickets/generatedTickets";
import { useIsMobileView } from "../../utils/responsive";
import { IWinner } from "../../store/interfaces/IWinner";
import { useAppSelector } from "../../store/hooks/useAppSelector";
import { RootState } from "../../store/types/rootState";
import { enqueueSnackbar } from "notistack";

interface ITopPurchaserProps {
  purchasers: ITopPurchaser[];
  raffleId: string;
}

export const PurchasersTable = ({ purchasers, raffleId }: ITopPurchaserProps) => {
  const [emailFilter, setEmailFilter] = useState<string>("");
  const [selectedEmailForSearch, setSelectedEmailForSearch] = useState<string>("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const loggedInAccountId = useAppSelector(
    ({ account }: RootState) => account.id,
  );

  const { data: ticketsFounded, isLoading } = useGetTicketsByPurchaserEmailQuery(
    {
      email: selectedEmailForSearch,
      raffleId,
    },
    {
      skip: selectedEmailForSearch === "",
    }
  );

  const handleViewTicketsClick = (email: string) => {
    setSelectedEmailForSearch(email);
    openDialog();
  };


  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailFilter(event.target.value.toLowerCase());
  };

  const filteredData = purchasers.filter((order: ITopPurchaser) => {
    if (emailFilter && !order.email.toLowerCase().includes(emailFilter)) {
      return false;
    }
    return true;
  }).sort((a: ITopPurchaser, b: ITopPurchaser) => {
    return b.ticketCount - a.ticketCount;
  });

  const [sendEmailByPurchaser, { data, isUninitialized }] = useSendEmailPurchaserMutation()
  const [sendingEmail, setSendingEmail] = useState<boolean>(false);

  useEffect(() => {

    if (!isUninitialized && data) {
      if (data) {
        enqueueSnackbar('Se ha enviado el correo con éxito!', {
          variant: "success",
          anchorOrigin: {
            horizontal: "right",
            vertical: "top"
          },
        })
      }
    }

  }, [data, isUninitialized]);

  const handleSendTicketsByPurchaser = (purchaser: IWinner) => {
    setSendingEmail(true)
    sendEmailByPurchaser({
      accountId: loggedInAccountId,
      purchaserEmail: purchaser.email,
      purchaserName: purchaser.name,
      raffleId
    }).then(response => {
      setSendingEmail(false)
    })
  };

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const isMobile = useIsMobileView();

  const dialogContent = (
    <DialogContent>
      {ticketsFounded && !isLoading && (
        <Box display={"flex"} justifyContent={"center"} width={"100%"}>
          <GeneratedTickets generatedTickets={ticketsFounded}></GeneratedTickets>
        </Box>
      )}
    </DialogContent>
  );

  return (
    <Box mt={2}>
      <Card variant="outlined">
        <CardContent>
          {isLoading || sendingEmail &&  <Box sx={{ width: '100%', my: 6 }}>
                <Typography color={"white"}>Enviando correo</Typography>
                <LinearProgress title="Enviando correo" />
            </Box>}
          {purchasers.length === 0 ? (
            <Typography variant="body1">No hay compradores</Typography>
          ) : (
            <>
              <Box paddingBottom={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={3}>
                    <TextField
                      label="Filtrar por email"
                      variant="outlined"
                      value={emailFilter}
                      onChange={handleEmailChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Box>
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{
                            backgroundColor: "black",
                            color: "white",
                            whiteSpace: "nowrap",
                          }}
                        >
                          # tickets
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor: "black",
                            color: "white",
                            whiteSpace: "nowrap",
                          }}
                        >
                          nombre
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor: "black",
                            color: "white",
                            whiteSpace: "nowrap",
                          }}
                        >
                          email
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor: "black",
                            color: "white",
                            whiteSpace: "nowrap",
                          }}
                        >
                          phone
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor: "black",
                            color: "white",
                            whiteSpace: "nowrap",
                          }}
                        >
                          actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredData.map(
                        (topPurchaser: ITopPurchaser, index: number) => (
                          <TableRow key={index}>
                            <TableCell
                              sx={{
                                whiteSpace: "nowrap",
                              }}
                            >
                              {topPurchaser.ticketCount}
                            </TableCell>
                            <TableCell>{topPurchaser.name}</TableCell>
                            <TableCell>{topPurchaser.email}</TableCell>
                            <TableCell>{topPurchaser.phone}</TableCell>
                            <TableCell>
                              <Button
                                variant="contained"
                                onClick={() => handleViewTicketsClick(topPurchaser.email)}
                              >
                                Ver números
                              </Button>
                              <Button
                                variant="outlined"
                                sx={{ ml: 2, textTransform: 'capitalize' }}
                                onClick={() => handleSendTicketsByPurchaser(topPurchaser)}
                              >
                                Reenviar números
                              </Button>
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </>
          )}
        </CardContent>
      </Card>
      <Dialog open={isDialogOpen} onClose={closeDialog} fullWidth={true} maxWidth={"xl"} fullScreen={isMobile}>
        <DialogTitle>Tickets generados para {selectedEmailForSearch}</DialogTitle>
        {dialogContent}
        <DialogActions>
          <Button onClick={closeDialog} variant="contained" color="primary">
            cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
