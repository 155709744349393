import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import * as React from 'react';
import { useAuthMutation, useGetAccountQuery } from '../../store/api';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { logInAction } from '../../store/slices/auth';
import { useDispatch } from 'react-redux';
import { saveUserAction } from '../../store/slices/user';
import { useAppSelector } from '../../store/hooks/useAppSelector';
import { RootState } from '../../store/types/rootState';
import { IAuthenticationRequest } from '../../store/interfaces/IAuth';
import { CircularProgress, Skeleton } from '@mui/material';
import { createAppTheme } from '../../utils/AppTheme';

export default function SignIn() {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const { isLoading: isLoadingAccount } = useGetAccountQuery(null, {});

  const accountInformation = useAppSelector(
    ({ account }: RootState) => account,
  );

  const [auth, { isLoading }] = useAuthMutation();

  const handleSubmit = async(event: React.FormEvent<HTMLFormElement>) => {

    event.preventDefault();
    const form = new FormData(event.currentTarget);
    const credentials = { username: form.get('email'), password: form.get('password') } as IAuthenticationRequest;
    
    
    try {
      const result = await auth(credentials).unwrap();
    if (result) {

      dispatch(logInAction({
        isLoggedIn: true,
        token: result.token
      }));
      
      dispatch(saveUserAction(result));
      navigate("/dashboard");
    }
    } catch (error) {
      enqueueSnackbar('Error al momento de iniciar sesión!', {
        variant: "error",
        anchorOrigin: {
          horizontal: "right",
          vertical: "top"
        },
      })
    }

  };



  return (
    <ThemeProvider theme={createAppTheme(accountInformation.id)}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {!isLoadingAccount ? <Box display={"flex"} justifyContent={"center"} flexDirection={"column"} alignItems={"center"}>
            <img className='filterdropshadow' src={accountInformation.logo} alt='Motorcycle' height={80} width={80} />
            <Typography component="h1" variant="h4">
              {accountInformation.name}
            </Typography>
          </Box> : <Box display={"flex"} justifyContent={"center"} flexDirection={"column"} alignItems={"center"}>
            <Skeleton animation="wave" variant="circular" width={90} height={90} />
            <Skeleton animation="wave" height={30} width={200} />
          </Box>}

          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Ingresar {isLoading ? <CircularProgress color='secondary'/>: ""}
            </Button>
            {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Olvidaste tu clave?
                </Link>
              </Grid>
            </Grid> */}
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}