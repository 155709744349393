
import { Box, Typography } from "@mui/material"
import { Dashboard } from "../dashboard"
import { useIsMobileView } from "../../utils/responsive";



export const DashboardPage = () => {
    const isMobileView = useIsMobileView();
    return (
        <Box paddingX={isMobileView ? 2 : 10} marginTop={2} paddingBottom={8}>
        <Typography variant="h6" fontFamily={"Georgia"} paddingBottom={2}>
            Dashboard
        </Typography>
        <Dashboard />
    </Box>
)
}