const STORE_NAME = process.env.REACT_APP_STORE_NAME ?? "raffle-store";
const ACCOUNT_ID = process.env.REACT_APP_ACCOUNT_ID ?? "moto-pirueta";


const getPaypalClientIdByOrigin =  (domain: string): string => {
  const origin = domain.replaceAll(".", "_").replaceAll(":", "_").toUpperCase()
  const envPath = `REACT_APP_PAYPAL_CLIENT_ID_${origin}`;
  return process.env[envPath] || "";
}

export {
  STORE_NAME,
  ACCOUNT_ID,
  getPaypalClientIdByOrigin,
};
