import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import { Card, CardContent } from '@mui/material';
import { useIsMobileView } from '../../../utils/responsive';
import { STEPS } from '../../../constants/createRaffleSteps';
import { AwardsStep } from './steps/awardsStep';
import { MainFeaturesStep } from './steps/mainFeaturesStep';
import { SummaryRaffleStep } from './steps/summaryRaffleStep';
import { CreateStepperRaffleProps } from '../../../domain/CreateStepperRaffleProps';
import { BankAccountSteps } from './steps/bankAccountsStep';


export default function DesktopCreateStepperRaffle({resource, setResource, handleCreateRaffle, disableNext, setDisableNext, awards, setAwards }: CreateStepperRaffleProps) {
  const [activeStep, setActiveStep] = React.useState(0);

  const totalSteps = () => {
    return STEPS.length;
  };


  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    const nextStep = activeStep + 1;
    setActiveStep(nextStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleValidateRaffleCreation = () => {
    if (handleCreateRaffle) {
      handleCreateRaffle(resource); 
    }
  }


  const isMobile = useIsMobileView();

  return (
    <Box mx={isMobile ? 1 : 5} px={isMobile ? 1 : 5} pt={2}>
      <Card>
        <CardContent>
          <Stepper nonLinear={false} activeStep={activeStep}>
            {STEPS.map(({ label }, index) => (
              <Step key={label}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === 0 && <MainFeaturesStep awards={awards} setAwards={setAwards} resource={resource} setResource={setResource} setDisableNext={setDisableNext} />}
            {activeStep === 1 && <AwardsStep resource={resource} setResource={setResource} setDisableNext={setDisableNext} awards={awards} setAwards={setAwards} />}
            {/* {activeStep === 2 && <BankAccountSteps resource={resource} setResource={setResource} setDisableNext={setDisableNext} awards={awards} setAwards={setAwards} />} */}
            {activeStep === 2 && <SummaryRaffleStep awards={awards} setAwards={setAwards} resource={resource} setResource={setResource} />}
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              {disableNext}
              <Box sx={{ flex: '1 1 auto' }} />
              {isLastStep() ? <Button onClick={handleValidateRaffleCreation} sx={{ mr: 1 }}>
                Create
              </Button> : <Button onClick={handleNext} disabled={disableNext} sx={{ mr: 1 }}>
                Next
              </Button>}
              
            </Box>
          </div>
        </CardContent>
      </Card>
    </Box>
  );
}
