import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IAccount } from "../interfaces/IAccount";
import { AccountStateDefault } from "../states/accountState";

const accountSlice = createSlice({
    name: 'account',
    initialState: AccountStateDefault,
    reducers: {
        saveAccountAction: (state, { payload }: PayloadAction<IAccount | null>) => {
            return {
              ...state,
              ...payload,
            };
          },
    }
})

export const { saveAccountAction } = accountSlice.actions;

export default accountSlice.reducer;