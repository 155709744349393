import { Chip, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { IRaffle } from "../../../store/interfaces/IRaffle";


interface RaffleSelectorProps {
    selectedRaffleId: string; 
    handleSelectRaffle: (e: SelectChangeEvent) => void;
    rafflesFounded: IRaffle[]
    setSelectedRaffleId: (e: string) => void;
    setSelectedRaffleLimit?: (e: number) => void;
}

export const RaffleSelector =({selectedRaffleId, handleSelectRaffle , rafflesFounded, setSelectedRaffleId, setSelectedRaffleLimit}: RaffleSelectorProps) => {
    return <FormControl
      sx={{
        width: "100%"
      }}
    >
      <InputLabel id="demo-simple-select-label">
        Seleccionar rifa
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedRaffleId}
        label="Seleccionar rifa"
        onChange={handleSelectRaffle}
      >
        {rafflesFounded.map((raffle) => (
          <MenuItem
            key={raffle.id}
            value={raffle.id}
            onClick={() => {
              setSelectedRaffleId(raffle.id);
              if (setSelectedRaffleLimit != undefined) {
                setSelectedRaffleLimit(raffle.limit)
              }

            }}
          >
            <Typography paddingRight={2}>
              {raffle.name}
              {raffle.status === 'ACTIVE' && <Chip sx={{ marginLeft: 1 }} label="Activa" color="primary" />}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>;
  }