import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Card, CardContent, CardHeader, Stack } from '@mui/material';
import { STEPS } from '../../../constants/createRaffleSteps';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { CreateStepperRaffleProps } from '../../../domain/CreateStepperRaffleProps';


export default function MobileCreateStepperRaffle(createStepperRaffleProps: CreateStepperRaffleProps) {
  const [activeStep, setActiveStep] = React.useState(0);

  

  const totalSteps = () => {
    return STEPS.length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    if (isLastStep() && createStepperRaffleProps.handleCreateRaffle) {
      createStepperRaffleProps.handleCreateRaffle(createStepperRaffleProps.resource); 
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box px={2} pt={2}>
      <Card>
      <CardHeader
              title={
                  <Typography variant="h6">
                    Crear rifa
                  </Typography>
              }
            />
        <CardContent>
          <Stepper activeStep={activeStep} orientation="vertical">
            {STEPS.map((step, index) => (
              <Step key={step.label}>
                <StepLabel
                  optional={
                    index === 2 ? (
                      <Typography variant="caption">Last step</Typography>
                    ) : null
                  }
                >
                  {step.label}
                </StepLabel>
                <StepContent>
                  {step.component(createStepperRaffleProps)}
                  <Stack direction={"row"} justifyContent={'space-between'}>
                      <Button
                        disabled={index === 0}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        <ArrowCircleLeftIcon />
                      </Button>
                      <Button
                        onClick={handleNext}
                        sx={{ mt: 1, mr: 1 }}
                        variant={index === STEPS.length -1 ? "contained" : "outlined"}
                        disabled={createStepperRaffleProps.disableNext}
                      >
                        {index === STEPS.length -1 ? <Typography>Crear rifa</Typography> : <ArrowCircleRightIcon />}
                      </Button>
                  </Stack>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </CardContent>
      </Card>
    </Box>
  );
}