import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks/useAppSelector';
import { RootState } from '../../store/types/rootState';
import { Button, Grid, ListItemIcon, Menu, MenuItem, Skeleton, Tooltip } from '@mui/material';
import { Logout, Person } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { logoutAction } from '../../store/slices/auth';
import { saveUserAction } from '../../store/slices/user';
import { useGetAccountQuery } from '../../store/api';
import { saveAccountAction } from '../../store/slices/account';
import { useAppDispatch } from '../../store/hooks/useAppDispatch';
import { PAGES } from '../../constants/pages';
import { IWinner } from '../../store/interfaces/IWinner';


function Header() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();


  const isLoggedIn = useSelector(({ authentication }: RootState) => authentication.isLoggedIn);
  const accountInformation = useAppSelector(({ account }: RootState) => account);


  // const avatarName = useAppSelector(({ user }: RootState) => {
  //   if (user.name === undefined) {
  //     return "";
  //   }

  //   return user.name.charAt(0) + user.lastname.charAt(0);
  // });

  const { data: accountFounded, error: errorGetAccount, isUninitialized, isLoading, isError } = useGetAccountQuery(null, {});

  React.useEffect(() => {
    if (!errorGetAccount || isError) {
      dispatch(saveAccountAction(accountFounded));
    }
  }, [accountFounded, dispatch, errorGetAccount, isError]);

  const handleNavigationTo = (componentUrl: string) => {
    navigate(componentUrl);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };



  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    dispatch(
      logoutAction({
        isLoggedIn: false,
        token: "",
      })
    );

    dispatch(saveUserAction(null));

    navigate("/login");

    setAnchorEl(null);
  };

  const skeletonHeader = <Box sx={{ width: '80%' }}>
  {[1, 2].map((item) => (
    <Skeleton
      key={item}
      animation="wave"
      variant="text"
      sx={{ borderRadius: 4 }}
    />
  ))}
</Box>;

  return !isUninitialized && isLoading ? skeletonHeader : !isError ? (
      <AppBar position="static" sx={{
        height: "100%",
      }}>
          <Grid container>

        {isLoggedIn && (<>

          <Grid item xl={1} lg={1} md={1} xs={1} sm={1} padding={1}>
              <Typography
                variant="h6"
                component="div"
                onClick={() => navigate("/dashboard")}
              >
                <img
                  src={accountInformation.logo}
                  width={50}
                  height={50}
                  alt=""
                />
              </Typography>
          </Grid>
          <Grid item xl={8} lg={8} md={8} xs={8} sm={8}>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          {PAGES.map((page) => (
            <Button
              onClick={() => handleNavigationTo(page.url)}
              key={page.name}
              size='small'
              sx={{ my: 1, mx: 1, display: 'block', fontSize: 18, mt: 2.5  }}
              variant='contained'
            >
              {page.name}
            </Button>
          ))}
        </Box>
      </Grid>
          <Grid item xl={3} lg={3} md={3} xs={3} sm={3} display={"flex"} justifyContent={"flex-end"}>
              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ mr: 2 }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <Person fontSize='large' sx={{
                    color: "lightblue !important",
                  }} />
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >

                {PAGES.map((page) => (
                  <MenuItem onClick={() => handleNavigationTo(page.url)} key={page.name}>
                    <ListItemIcon>
                      {page.icon}
                    </ListItemIcon>
                    {page.name}
                  </MenuItem>
                ))}

                <MenuItem onClick={logout}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </Grid>
            </>
        )}
          </Grid>
      </AppBar>
  ) : skeletonHeader;
}

export default Header;
