import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UserStateDefault } from "../states/userState";
import { IUser } from "../interfaces/IUser";

const userSlice = createSlice({
    name: 'user',
    initialState: UserStateDefault,
    reducers: {
        saveUserAction: (state, { payload }: PayloadAction<IUser | null>) => {
            return {
              ...state,
              ...payload,
            };
          },
    }
})

export const { saveUserAction } = userSlice.actions;

export default userSlice.reducer;