import React from "react";
import { Grid, Typography, Paper } from "@mui/material";

export const NoActiveRaffle = () => {
  return (
    <Grid container justifyContent="center" alignItems="center" minHeight="calc(100vh - 64px)">
      <Grid item xs={12} sm={8} md={6} lg={4}>
        <Paper elevation={3} style={{ padding: "20px" }}>
          <Typography variant="h6" align="center" color="textSecondary">
            No hay rifa activa en este momento
          </Typography>
          <Typography variant="body1" align="center" color="textSecondary" style={{ marginTop: "10px" }}>
            ¡Mantente al tanto de las próximas rifas y emocionantes premios!
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};
