import { useEffect } from "react";
import { Box, Typography, TextField, Button, IconButton, Grid, Card, CardContent, CardHeader, Stack, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { buttonAddStyle } from "../../../../utils/styleConstants";
import { useIsMobileView } from "../../../../utils/responsive";
import { CreateStepperRaffleProps } from "../../../../domain/CreateStepperRaffleProps";
import { AWARD_TYPES_ICONS } from "../../../../constants/icons";

export const AwardsStep = ({ resource, setResource, setDisableNext, awards, setAwards }: CreateStepperRaffleProps) => {

  useEffect(() => {
    setResource((prevResource) => ({
      ...prevResource,
      awards: awards,
    }));

    const isFormValid = awards.every((award) => {
      return (
        award.name.trim() !== "" &&
        award.features.length > 0 &&
        award.images.length > 0
      );
    });

    if (setDisableNext !== undefined) {
      setDisableNext(!isFormValid || awards.length === 0);
    }

  }, [awards, setResource, setDisableNext]);


  useEffect(() => {
    setResource((prevResource) => ({
      ...prevResource,
      awards: awards,
    }));
  }, [awards, setResource]);

  const handleAddReward = () => {
    if (awards.length >= 2) return;
    setAwards([...awards, { name: "", features: ["-"], images: [], order: awards.length, type: "car" }]);
  };

  const handleNameChange = (index: number, value: string) => {
    const updatedAwards = [...awards];
    updatedAwards[index].name = value;
    setAwards(updatedAwards);
  };

  const handleAwardTypeChange = (index: number, value: string) => {
    const updatedAwards = [...awards];
    updatedAwards[index].type = value;
    setAwards(updatedAwards);
  };

  const handleFeatureChange = (awardIndex: number, featureIndex: number, value: string) => {
    const updatedAwards = [...awards];
    updatedAwards[awardIndex].features[featureIndex] = value;
    setAwards(updatedAwards);
  };

  const handleAddFeature = (awardIndex: number) => {
    if (awards[awardIndex].features.length >= 3) return;
    const updatedAwards = [...awards];
    updatedAwards[awardIndex].features.push("");
    setAwards(updatedAwards);
  };

  const handleRemoveFeature = (awardIndex: number, featureIndex: number) => {
    const updatedAwards = [...awards];
    updatedAwards[awardIndex].features.splice(featureIndex, 1);
    setAwards(updatedAwards);
  };

  const handleRemoveImage = (awardIndex: number, imageIndex: number) => {
    const updatedAwards = [...awards];
    updatedAwards[awardIndex].images.splice(imageIndex, 1);
    setAwards(updatedAwards);
  };

  const handleDeleteReward = (awardIndex: number) => {
    const updatedAwards = [...awards];
    updatedAwards.splice(awardIndex, 1);
    setAwards(updatedAwards);
  };

  const handleImageChange = (index: number, files: FileList | null) => {
    if (!files) return;
    const imagesToAdd = Array.from(files).slice(0, 3);
    const updatedAwards = [...awards];
    updatedAwards[index].images.push(...imagesToAdd);
    setAwards(updatedAwards);
  };

  const imagePreviews = (awardIndex: number) => {
    return awards[awardIndex].images.map((image, imageIndex) => (
        <Box  key={`image-preview-${imageIndex}`}>
          <img src={URL.createObjectURL(image)} alt={`Preview ${imageIndex + 1}`} width={"100%"} />
          <Box display={"flex"} justifyContent={"center"}>
            <IconButton onClick={() => handleRemoveImage(awardIndex, imageIndex)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
    ));
  };

  const featureInputs = (awardIndex: number) => {
    return awards[awardIndex].features.map((feature, featureIndex) => (
      <Grid item xs={12} key={`feature-input-${featureIndex}`}>
        <Box mt={featureIndex > 0 ? 2 : 1} display="flex" alignItems="center">
          <TextField
            label={`Caracteristica ${featureIndex + 1}`}
            variant="outlined"
            fullWidth
            value={feature}
            inputProps={{ maxLength: 48 }}
            onChange={(e) => handleFeatureChange(awardIndex, featureIndex, e.target.value)}
            key={`feature-input-${featureIndex}`} // Add key prop
          />
          <IconButton onClick={() => handleRemoveFeature(awardIndex, featureIndex)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      </Grid>
    ));
  };

  const isMobile = useIsMobileView();

  return (
    <Box p={isMobile ? 0 : 3}>
      {awards.map((award, awardIndex) => (
        <Card key={`award-${awardIndex}`} sx={{ marginBottom: 2 }}>
          <CardHeader
            action={
              <IconButton aria-label="settings" onClick={() => handleDeleteReward(awardIndex)}>
                <Typography>
                  Borrar premio
                </Typography>
                <DeleteIcon />
              </IconButton>
            }
            title={award.name}
          />
          <CardContent>
            <Box mt={awardIndex > 0 ? 4 : 0} mb={4}>
              <TextField
                label="Nombre"
                variant="outlined"
                fullWidth
                value={award.name}
                inputProps={{ maxLength: 24 }}
                onChange={(e) => handleNameChange(awardIndex, e.target.value)}
                key={`name-input-${awardIndex}`}
              />


      <Box mt={2}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Tipo de premio</InputLabel>
          <Select
            required
            label="Tipo de concurso"
            name="type"
            value={award.type}
            onChange={(e) => handleAwardTypeChange(awardIndex, e.target.value)}
          >
            {AWARD_TYPES_ICONS.map((element) => (
              <MenuItem key={element.id} value={element.id}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {element.icon}
                  <Typography ml={1}>{element.name}</Typography>
                </div>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>


              <Typography variant="h6" gutterBottom mt={2}>
                Caracteristicas (3)
              </Typography>
              <Grid container>
                {featureInputs(awardIndex)}
                {awards[awardIndex].features.length < 3 && (
                  <Button
                    sx={buttonAddStyle}
                    variant="contained"
                    component="label"
                    onClick={() => handleAddFeature(awardIndex)}
                  >
                    + Agregar caracteristica
                  </Button>
                )}
              </Grid>
              <Box mt={2}>
                <Typography variant="h6" gutterBottom>
                  Imagenes (3)
                </Typography>
                <Stack spacing={2} direction={isMobile ? "column" : "row" }>
                  {imagePreviews(awardIndex)}
                </Stack>
                {awards[awardIndex].images.length < 3 && (
                  <Button sx={buttonAddStyle} variant="contained" component="label">
                    + Agregar imagen
                    <input
                      type="file"
                      accept="image/*"
                      multiple
                      hidden
                      onChange={(e) => handleImageChange(awardIndex, e.target.files)}
                      key={`image-input-${awardIndex}`} // Add key prop
                    />
                  </Button>
                )}
              </Box>
            </Box>
          </CardContent>
        </Card>
      ))}
      {awards.length < 2 && (
        <Box mt={1}>
          <Button sx={buttonAddStyle} onClick={handleAddReward}>
            + Añadir premio
          </Button>
        </Box>
      )}
    </Box>
  );
};
