import { useGetRaffleWinnerQuery, useGetSoldTicketsQuery, useLazyGetRaffleWinnerQuery } from "../../store/api";
import { Box, Button, Dialog, Grid, LinearProgress, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { getPrimaryColorByAccountId, getThemeByAccountId, styleConstants } from "../../utils/styleConstants";
import { enqueueSnackbar } from "notistack";
import { Email, EmojiEvents, Person, Phone } from "@mui/icons-material";
import { useAppSelector } from "../../store/hooks/useAppSelector";
import { RootState } from "../../store/types/rootState";

interface SoldTicketsDashboardProps {
    raffleId: string;
    limit: number; // Make limit optional
}

export const SoldTicketsDashboard = ({ raffleId, limit }: SoldTicketsDashboardProps) => {
    const pageSize = limit === 10000 ? 1000 : limit;
    const [currentPage, setCurrentPage] = useState(1);
    const [getWinnerByTicket, {data, error, isLoading, isFetching}] = useLazyGetRaffleWinnerQuery();
    const [isLoadingGetWinner, setIsLoadingGetWinner] = useState<boolean>(false);
    const [openDialogWinner, setOpenDialogWinner] = useState<boolean>(false);
    const [winner, setWinner] = useState<any | undefined>({});
    const accountId = useAppSelector(
        ({ account }: RootState) => account.id,
      );

    const handleCloseWinnerDialog = () => {
        setOpenDialogWinner(false);
    };

    const handleShowWinner = (selectedNumber: number, isInSoldTickets: boolean | undefined) => {
        if (!isInSoldTickets) {
            enqueueSnackbar(`El ticket ${selectedNumber.toString().padStart(limit.toString().length - 1, '0')} no se ha vendido!`, {
                variant: "info",
                anchorOrigin: {
                    horizontal: "right",
                    vertical: "top",
                },
            });
        } else {
            setIsLoadingGetWinner(true);
            getWinnerByTicket({
                raffleId,
                ticket: selectedNumber
            }).then(({data}) => {
                if (data) {
                    setWinner(data);
                    setIsLoadingGetWinner(false);
                    setOpenDialogWinner(true);
                }
            });
        }
    };

    const { data: getSoldTicketsResponse } = useGetSoldTicketsQuery(raffleId);

    const colorByAccountId = getPrimaryColorByAccountId(accountId) === '#FFFFFF' ? 'blue': getPrimaryColorByAccountId(accountId);

    const renderTicketNumber = (number: number) => {
        const isInSoldTickets = getSoldTicketsResponse && getSoldTicketsResponse.includes(number);
        const color = isInSoldTickets ? colorByAccountId : getThemeByAccountId(accountId) === 'dark' ? "white" : "#000";
        return (
            <Paper sx={{ m: 1, p: 1, border: isInSoldTickets ? `1px solid ${colorByAccountId}` : 'none', width: 60, textAlign: "center", }} onClick={() => handleShowWinner(number, isInSoldTickets)}>
                <Typography textAlign={"center"} variant="body1" color={color} sx={isInSoldTickets ? { cursor: 'pointer' } : {}}>
                    {number.toString().padStart(limit.toString().length - 1, '0')}
                </Typography>
            </Paper>
        );
    };

    

    const renderTickets = () => {
        const ticketNumbers = [];
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = Math.min(startIndex + pageSize, limit);
        
        if (getSoldTicketsResponse) {
            for (let i = startIndex; i < endIndex; i++) {
                ticketNumbers.push(
                    <Grid item key={i}>
                        {renderTicketNumber(i)}
                    </Grid>
                );
            }
        }

        return ticketNumbers;
    };

    const totalPages = Math.ceil(limit / pageSize);

    return (
        <Grid container spacing={1}>
            {isLoading || isLoadingGetWinner &&  <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>}
            {renderTickets()}

            <Dialog open={openDialogWinner}>
                <Box display="flex" alignItems="center" sx={{ width: '100%' }}>
                    <Paper key={winner.email} elevation={3} className="winner-paper" sx={{ width: '100%' }}>
                        <Box p={2} display="flex" alignItems="center">
                            <EmojiEvents fontSize="large" color="primary" />
                            <Box ml={2}>
                                <Typography variant="subtitle1" color="textPrimary">
                                    <Person fontSize="small" color="action" /> {winner.name}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    <Email fontSize="small" color="action" /> {winner.email}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    <Phone fontSize="small" color="action" /> {winner.phone}
                                </Typography>
                            </Box>
                        </Box>
                        <Box p={2}>
                            <Button size="small" onClick={handleCloseWinnerDialog} variant="contained" fullWidth>OK</Button>
                        </Box>
                    </Paper>
                </Box>
            </Dialog>

{limit === 10000  && <Box mt={2} width={"100%"} display="flex" justifyContent="space-between">
                {Array.from({ length: totalPages }, (_, i) => (
                    <Button size="small" key={i + 1} onClick={() => setCurrentPage(i + 1)} sx={{ color: currentPage === i + 1 ? "secondary" : "gray" }} variant="text">
                        {i + 1}
                    </Button>
                ))}
            </Box>}
        </Grid>
    );
};
