const AUTH_URL = "authentication";
const RAFFLES_URL = "raffles";
const PURCHASE_ORDERS_URL = "purchaseOrders";
const ACCOUNTS_URL = "accounts";
const PAYMENTS_URL = "payments";
const TICKETS_URL = "tickets";
export {
  AUTH_URL,
  RAFFLES_URL,
  ACCOUNTS_URL,
  PAYMENTS_URL,
  TICKETS_URL,
  PURCHASE_ORDERS_URL
};
