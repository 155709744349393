import { FavoriteBorder, Comment, IosShare } from '@mui/icons-material';

export interface PromoStepItem {
    name: string;
    icon: any;
}

interface PromoStepConfig {
    accountId: string;
    promoSteps: PromoStepItem[];
    publicationLink: string;
}

export const PROMOTION_STEPS_CONFIG: PromoStepConfig[] = [
    {
        accountId: "oscar-alarcon-promo",
        publicationLink: "https://www.instagram.com/reel/C6peVujApxo",
        promoSteps: [
            {
                name: "Like",
                icon: (<FavoriteBorder></FavoriteBorder>),
            },
            {
                name: "Comenta",
                icon: (<Comment></Comment>),
            },
            {
                name: "Comparte",
                icon: (<IosShare></IosShare>),
            }
        ]
    },
]
