import { IUser } from "../interfaces/IUser";

export const UserStateDefault: IUser = {
     email: "test@example.com",
     lastname: "Test",
     name: "Test",
     profile: "Profile",
     status: "Active",
     token: "Token",
};
