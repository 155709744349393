import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useGenerateTicketsMutation, usePaymentCommitTransactionMutation, usePaymentInitTransactionMutation } from "../../../store/api";
import { PAYMENT_METHODS_ICONS } from "../../../constants/icons";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/types/rootState";

interface CashPaymentMethodProps {
  purchaserData: any;
  selectedPaymentMethod: string;
  raffleId: string;
  ticketQuantity: number;
  totalAmount: number;
  setOpen: (open: boolean) => void;
}

export const CashPaymentMethod: React.FC<CashPaymentMethodProps> = ({
  purchaserData,
  selectedPaymentMethod,
  raffleId,
  ticketQuantity,
  totalAmount,
  setOpen,
}: CashPaymentMethodProps) => {

  const paymentMethodId = "cash";
  const { enqueueSnackbar } = useSnackbar();
  const [orderId, setOrderId] = useState<string>("")
  const accountId = useSelector(({ account }: RootState) => account.id);
  
  const [initTransaction, { isLoading: isLoadingInitTransaction, data: dataInitTransaction, error: isErrorInitTransaction }] = usePaymentInitTransactionMutation();
  const [
    generateTickets,
    {
      data: generateTicketsResponse,
      isLoading: isLoadingGenerateTickets,
    },
  ] = useGenerateTicketsMutation();

  const [
    paymentCommit, { data, isLoading: isLoadingCommitTransaction },
  ] = usePaymentCommitTransactionMutation();


  const [password, setPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  useEffect(() => {
    if (generateTicketsResponse && generateTicketsResponse.status === "SUCCESSFUL") {
      enqueueSnackbar("Tickets generados exitosamente!", {
        variant: "info",
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
      }); 
      setOpen(false);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else if (generateTicketsResponse && generateTicketsResponse.status === "PROCESS_INITIALIZED") {
      enqueueSnackbar(`Se están generando los tickets, enviaremos el correo al usuario con sus números.`, {
        variant: "info",
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
      }); 
    }
  }, [enqueueSnackbar, setOpen, generateTicketsResponse])

  useEffect(() => {
    if (raffleId && raffleId !== "default" && orderId) {
      paymentCommit({
        conf: {
          paymentMethodId,
          orderId,
        },
        raffleId,
      });
    }
  }, [raffleId, paymentCommit, paymentMethodId, orderId]);

  useEffect(() => {
    if (data?.purchaseOrder && data.status !== "FAILED") {
      generateTickets({
        config: {
          purchaseOrderId: data.purchaseOrder,
          accountId: accountId,
        },
        raffleId: raffleId,
      });
    }
  }, [data, generateTickets, raffleId, accountId]);


  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleInitTransaction = async () => {
    try {
      if (!purchaserData.name || !purchaserData.email || !purchaserData.phone) {
        enqueueSnackbar("Ingrese todos los datos de contacto.", {
          variant: "error",
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
        });
        return;
      }

      if (!selectedPaymentMethod) {
        return;
      }

      const response = await initTransaction({
        raffleId,
        conf: {
          ticketQuantity,
          passwordCash: password,
          paymentMethodId: selectedPaymentMethod,
          purchaser: purchaserData,
        },
      }).unwrap();

      if (
        response.status === "AUTHORIZED_FOR_GENERATE_TICKETS" &&
        selectedPaymentMethod === "cash"
      ) {
        setOrderId(response.purchaseOrderId);
        return;
      }

      if (response.status === "LIMIT_EXCEEDED" || response.status === "RAFFLE_NO_LONGER_AVAILABLE" || response.status === "UNAUTHORIZED_FOR_GENERATE_TICKETS") {
        enqueueSnackbar(response.error, {
          variant: "error",
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
        });
      } else {
        enqueueSnackbar("Error al momento de ir al pago!", {
          variant: "error",
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
        });
      }
    } catch (error) {
      enqueueSnackbar("Error al momento de ir al pago!", {
        variant: "error",
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
      });
    }
  };

  interface LoadingComponentProps {
    message: string;
  }
  const LoadingComponent = ({ message }: LoadingComponentProps) => (
    <Box
      m={3}
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
      justifyContent="center"
    >
      <Typography >{message}</Typography>
      <CircularProgress
        variant="indeterminate"
        sx={{
          color: "#FFF",
        }}
      />
    </Box>
  );

  return (
    <>
      <Grid container>
        {(!dataInitTransaction ||
          dataInitTransaction.status === "UNAUTHORIZED_FOR_GENERATE_TICKETS") &&
          !isLoadingInitTransaction && (
            <Grid
              item
              sx={{
                width: "100%",
              }}
            >
              <Typography >
                Esta a punto de generar {ticketQuantity} tickets para el email{" "}
                {purchaserData?.email}. Asegurese de haber recibido el efectivo.
                Esta acción necesita contraseña.
              </Typography>
              <FormControl sx={{ width: "25ch", mt: 2 }} variant="outlined">
                <InputLabel htmlFor="password-generate-tickets">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="password-generate-tickets"
                  type={showPassword ? "text" : "password"}
                  onChange={handleChangePassword}
                  fullWidth
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>

              <Button
                disabled={
                  !selectedPaymentMethod ||
                  isLoadingInitTransaction ||
                  password === ""
                }
                fullWidth
                type="submit"
                color="primary"
                sx={{
                  opacity: password === "" ? 0.3 : 1,
                  backgroundColor: "#7b9a6d !important",
                  borderColor: "inherit",
                  mt: 2,
                }}
                variant="contained"
                startIcon={
                  PAYMENT_METHODS_ICONS("30px").find(
                    (element) => element.id === "cash"
                  )?.icon
                }
                onClick={handleInitTransaction}
              >
                {!isLoadingInitTransaction ? (
                  <>
                    <Typography color={"#000"} ml={1} fontWeight={"bold"}>
                      Comprar tickets{" "}
                      {selectedPaymentMethod && `(${totalAmount}$)`}
                    </Typography>
                  </>
                ) : (
                  <CircularProgress size="2rem"/>
                )}
              </Button>
            </Grid>
          )}

        {isLoadingInitTransaction && (<LoadingComponent message="Creando orden de compra" />)}
        {isLoadingCommitTransaction && (<LoadingComponent message="Confirmando orden de compra" />)}
        {isLoadingGenerateTickets && (<LoadingComponent message="Generando tickets" />)}
      </Grid>
    </>
  );
};

