import { configureStore, Store } from "@reduxjs/toolkit";
import { RootState } from "./types/rootState";
import { STORE_NAME } from "../constants/environment";
import rootReducers from "./reducers/rootReducers";
import api from "./api";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "authentication"]
};

const persistedReducer = persistReducer(persistConfig, rootReducers);

const store: Store<RootState> = configureStore({
  reducer: persistedReducer,
  devTools: {
    name: STORE_NAME,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(api.middleware),
});

export const getStoreWithState = (preloadedState?: RootState) =>
  configureStore({ reducer: persistedReducer, preloadedState });

export const persistor = persistStore(store);

export default store;
