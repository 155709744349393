import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import { IWinner } from "../../store/interfaces/IWinner";
import FormPayment from "./form/payment";

interface PurchaseDialogProps {
  raffleId: string;
  open: boolean;
  setOpen: (value: boolean) => void;
  isMobile: boolean;
  totalAmount: number;
  totalAmountUSD: number;
  ticketQuantity: number;
  availablePaymentMethods: string[];
}

export const PurchaseDialog = ({
  open,
  isMobile,
  setOpen,
  totalAmount,
  totalAmountUSD,
  availablePaymentMethods,
  ticketQuantity,
  raffleId,
}: PurchaseDialogProps) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<string | null>(null);
  const [purchaserData, setPurchaserData] = useState<IWinner>({
    name: "",
    email: "",
    phone: "",
  });

  const handlePaymentMethodSelect = (method: string) => {
    setSelectedPaymentMethod((prevMethod) =>
      prevMethod === method ? null : method
    );
  };
  
  return (
    <Dialog open={open} fullScreen={isMobile} maxWidth="xl">
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      <DialogTitle>Pagar {ticketQuantity} tickets</DialogTitle>
      <DialogContent>
       <FormPayment 
       minPurchase={2}
       availablePaymentMethods={availablePaymentMethods}
       handlePaymentMethodSelect={handlePaymentMethodSelect}
       purchaserData={purchaserData}
       raffleId={raffleId}
       selectedPaymentMethod={selectedPaymentMethod}
       setOpen={setOpen}
       setPurchaserData={setPurchaserData}
       ticketQuantity={ticketQuantity} 
       totalAmount={totalAmount}
       totalAmountUSD={totalAmountUSD}
       />
      </DialogContent>
    </Dialog>
  );
};
