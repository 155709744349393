
import { IAuth } from "../interfaces/IAuth";
import { AuthStateDefault } from "../states/authState";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "authentication",
  initialState: AuthStateDefault,
  reducers: {
    logInAction: (state, { payload }: PayloadAction<IAuth>) => {
      return {
        ...state,
        ...payload,
      };
    },
    logoutAction: (state, { payload }: PayloadAction<IAuth>) => {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export const { logInAction, logoutAction } = authSlice.actions;

export default authSlice.reducer;
