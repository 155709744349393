import { RootState } from "../../store/types/rootState";
import { useAppSelector } from "../../store/hooks/useAppSelector";
import NotFoundPage from "../layout/404";

export interface PrivateRouteProps {
  children: React.ReactElement;
}

const PrivateRoute = ({ children }: PrivateRouteProps) => {

  const isLoggedIn = useAppSelector(
    ({ authentication }: RootState) => authentication.isLoggedIn,
  );

  if (!isLoggedIn) {
    return <NotFoundPage/>;
  }

  return children;
};
export default PrivateRoute;
