import { Stack, CircularProgress, Typography } from "@mui/material";

const styles = {
    loadingStack: {
      display: "flex",
      height: "650px",
      padding: 3,
      "& span": {
        color: "#2C3041",
      },
    },
  };
  
interface LoadingProps {
  title?: string;
}

export const Loading = ({ title }: LoadingProps): JSX.Element => {
  return (
    <Stack
      alignItems="center"
      data-testid="stack-circular-progress"
      justifyContent="center"
      sx={styles.loadingStack}
    >
      <Typography variant="h6">
          {title}
      </Typography>
      <CircularProgress />
    </Stack>
  );
};
