import React from 'react';
import { Box, Typography, Card, CardContent, Table, TableBody, TableCell, TableRow } from "@mui/material";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { ITopPurchaser } from '../../store/interfaces/ITopPurchaser';
import { useIsMobileView } from '../../utils/responsive';

interface TopPurchasersProps {
  topPurchasers: ITopPurchaser[];
}

const TopPurchasers: React.FC<TopPurchasersProps> = ({ topPurchasers }) => {
  const renderTrophyIcon = (index: number) => {
    if (index === 0) {
      return <EmojiEventsIcon sx={{ fontSize: 30, color: 'gold' }} />;
    } else if (index === 1) {
      return <EmojiEventsIcon sx={{ fontSize: 30, color: 'silver' }} />;
    } else if (index === 2) {
      return <EmojiEventsIcon sx={{ fontSize: 30, color: 'brown' }} />;
    }
    return null;
  };

  const orderedTopPurchasers = [...topPurchasers].sort((a, b) => b.ticketCount - a.ticketCount);

  const isMobile = useIsMobileView();

  return (
    <Box mt={2}>
      <Typography variant="h6">Top compradores:</Typography>
      <hr />
      {isMobile ? (
        <Box>
          {orderedTopPurchasers.map((purchaser, index) => (
            <Card key={purchaser.email} sx={{ marginBottom: 2 }}>
              <CardContent sx={{
                textAlign: 'center'
              }}>
                <Typography>{renderTrophyIcon(index)}</Typography>
                <Typography fontWeight="bold">{purchaser.ticketCount}</Typography>
                <Typography>{purchaser.name}</Typography>
                <Typography>{purchaser.email}</Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
      ) : (
        <Table>
          <TableBody>
            {orderedTopPurchasers.map((purchaser, index) => (
              <TableRow key={purchaser.email}>
                <TableCell>
                  <Typography>{renderTrophyIcon(index)}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{purchaser.name}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{purchaser.email}</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight="bold">{purchaser.ticketCount}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {orderedTopPurchasers.length === 0 && (
        <Typography >No hay compradores.</Typography>
      )}
    </Box>
  );
};

export default TopPurchasers;
