
import { Box, Typography } from "@mui/material"
import { useIsMobileView } from "../../utils/responsive";
import TicketsFinder from "../ticketsFinder";
import { useAppDispatch } from "../../store/hooks/useAppDispatch";
import { useAppSelector } from "../../store/hooks/useAppSelector";
import { saveActiveRaffleAction } from "../../store/slices/raffle";
import { Loading } from "../layout/loading";
import { RootState } from "../../store/types/rootState";
import { useGetActiveRaffleQuery } from "../../store/api";
import { NoActiveRaffle } from "../home/NoActiveRaffle";



export const TicketsFinderPage = () => {
    const dispatch = useAppDispatch();

    const accountId = useAppSelector(
      ({ account }: RootState) => account.id,
    );
  
    const { data: activeRaffleFounded, isLoading: isLoadingGetActiveRaffle, error: errorGetActiveFaffle, isUninitialized } = useGetActiveRaffleQuery(accountId, {
      skip: accountId === "default" || accountId === "",
    });
    if (!isLoadingGetActiveRaffle && !errorGetActiveFaffle && !isLoadingGetActiveRaffle && activeRaffleFounded) {
        dispatch(saveActiveRaffleAction(activeRaffleFounded));
    }
  
    return !isLoadingGetActiveRaffle && !isUninitialized ? (
      <>
        {activeRaffleFounded ? <TicketsFinder raffleId={activeRaffleFounded.id} activeRaffleName={activeRaffleFounded.name} /> : <NoActiveRaffle />}
      </>
    ) : <Loading title="Buscando rifas disponibles"></Loading>;
}