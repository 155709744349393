import MeliPayLogo from "../assets/images/svg/mercadopago.png";
import PaypalLogo from "../assets/images/svg/paypal.png";
import WebpayLogo from "../assets/images/svg/webpay.png";
import RaffleLogo from "../assets/images/svg/raffle.png";
import CarIcon from "../assets/images/svg/steering-wheel.png";
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import GiteIcon from '@mui/icons-material/Gite';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import CashIcon from "../assets/images/svg/cash.png";
import TransferIcon from "../assets/images/svg/transfer_icon.png";

export const RAFFLE_TYPE_ICONS = [
  // {
  //   id: "lottery",
  //   name: "Loteria",
  //   icon: (
  //     <img
  //       style={{
  //         height: "20px",
  //       }}
  //       src={LotteryLogo}
  //       alt=""
  //     />
  //   ),
  // },
  {
    id: "raffle",
    name: "Sorteo",
    icon: (
      <img
        style={{
          height: "20px",
        }}
        src={RaffleLogo}
        alt=""
      />
    ),
  },
];


export const AWARD_TYPES_ICONS = [
  {
    id: "car",
    name: "Auto",
    icon: (
      <img
        style={{
          height: "20px",
        }}
        src={CarIcon}
        alt=""
      />
    ),
  },
  {
    id: "motorcycle",
    name: "Moto",
    icon: (<SportsMotorsportsIcon/>),
  },
  {
    id: "phone",
    name: "Celular",
    icon: (
      <PhoneIphoneIcon />
    ),
  },
  {
    id: "cash",
    name: "Dinero",
    icon: (
      <AttachMoneyIcon />
    ),
  },
  {
    id: "house",
    name: "Casa",
    icon: (
      <GiteIcon />
    ),
  },
  {
    id: "department",
    name: "Departamento",
    icon: (
     <ApartmentIcon />
    ),
  },
];

export const PAYMENT_METHODS_ICONS = (size: string) => [
  {
    id: "transbank",
    title: "Transbank",
    icon: (
      <img
        style={{
          height: size,
        }}
        src={WebpayLogo}
        alt=""
      />
    ),
  },
  {
    id: "paypal",
    title: "Paypal",
    icon: (
      <img
        style={{
          height: size,
        }}
        src={PaypalLogo}
        alt=""
      />
    ),
  },
  {
    id: "mercadopago",
    title: "Mercado Pago",
    icon: (
      <img
        style={{
          height: size,
        }}
        src={MeliPayLogo}
        alt=""
      />
    ),
  },
  {
    id: "cash",
    title: "Cash",
    icon: (
      <img
        style={{
          height: size,
        }}
        src={CashIcon}
        alt=""
      />
    ),
  },
  {
    id: "transfer",
    title: "Transferencia",
    icon: (
      <img
        style={{
          height: size,
        }}
        src={TransferIcon}
        alt=""
      />
    ),
  },
];
