import { Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, TextField } from "@mui/material"
import { useEffect, useState } from "react";
import { useIsMobileView } from "../../utils/responsive";
import WinnerSearchResults from "./winnerSearch";
import CloseIcon from '@mui/icons-material/Close';
import { useActiveRaffleMutation, useGetActiveRaffleQuery } from "../../store/api";
import { useAppSelector } from "../../store/hooks/useAppSelector";
import { RootState } from "../../store/types/rootState";
import { enqueueSnackbar } from "notistack";

interface RaffleActionsProps {
  isActiveRaffle: boolean;
  raffleId: string;
}

function useDebounce(value: string, delay: number): string {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export const RaffleActions = ({ isActiveRaffle, raffleId }: RaffleActionsProps) => {

    const accountId = useAppSelector(
      ({ account }: RootState) => account.id,
    );

    const { data: activeRaffleFounded, refetch } = useGetActiveRaffleQuery(accountId, {
      skip: accountId === "default" || accountId === "",
    });

    const [open, setOpen] = useState(false);
    const [openActiveRaffle, setOpenActiveRaffle] = useState(false);

    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    const handleClose = () => {
      setOpen(false);
    };
   
    const isMobile = useIsMobileView();

    const [activeRaffle] = useActiveRaffleMutation();

    const handleActiveRaffle = () => {
      activeRaffle(raffleId).then((response: any) => {
        if (response && response.data) {
          enqueueSnackbar(`Se ha activado la rifa con éxito.`, {
            variant: "success",
            anchorOrigin: {
              horizontal: "right",
              vertical: "top",
            },
          }); 
          setOpenActiveRaffle(false)
          setTimeout(() => {
            window.location.reload();  
          }, 1500);
        } else {
          enqueueSnackbar(`Hubo un error al activar la rifa.`, {
            variant: "error",
            anchorOrigin: {
              horizontal: "right",
              vertical: "top",
            },
          }); 
          setOpenActiveRaffle(false)
        }
      }).catch(error => {
        enqueueSnackbar(`Hubo un error al activar la rifa.`, {
          variant: "error",
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
        }); 
        setOpenActiveRaffle(false)
      })
    }

    return (
      <Box pl={10} paddingY={2} display={"flex"} justifyContent={"end"}>
        {!isActiveRaffle && (
          <Button variant="contained" color="primary" onClick={() => setOpenActiveRaffle(true)}>
            Activar rifa
          </Button>
        )}
        {isActiveRaffle && (
          <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
            Cerrar rifa activa
          </Button>
        )}

        <Dialog open={open} onClose={handleClose} fullScreen={isMobile}>
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle>Cerrar rifa</DialogTitle>
          <DialogContent sx={{ 
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}>
            <DialogContentText paddingBottom={2}>
              Para poder cerrar la rifa por favor digite el numero ganador para notificar a la persona.
            </DialogContentText>
            <TextField
              InputProps={{ inputProps: { min: 0, max: 10000 } }}
              id="ticket"
              label="Número ganador"
              type="number"
              fullWidth
              variant="standard"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <WinnerSearchResults setOpen={setOpen} raffleId={raffleId} ticket={Number.parseInt(debouncedSearchTerm)} />
          </DialogContent>
        </Dialog>
        
        <Dialog open={openActiveRaffle} onClose={() => setOpenActiveRaffle(false)} fullScreen={isMobile}>
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle>Activar rifa</DialogTitle>
          <DialogContent sx={{ 
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}>
            <DialogContentText paddingBottom={2}>
              {activeRaffleFounded ? `No se puede activar la rifa ya que la rifa: ${activeRaffleFounded.name} ya está activa, desactive la rifa en curso y vuelva a intentarlo.` : 'Está seguro que desea desactivar la rifa?'}
            </DialogContentText>
            {activeRaffleFounded ? <Button fullWidth variant="contained" onClick={() => setOpenActiveRaffle(false)}>
              Cerrar
            </Button> : <Button fullWidth variant="contained" onClick={handleActiveRaffle}>
              Activar
            </Button>
            }
          </DialogContent>
        </Dialog>
      </Box>
    );
}
