import React, { useEffect, useState } from "react";
import { useGetPurchasersQuery, useGetRafflesQuery } from "../../store/api";
import { Box, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { RaffleSelector } from "../raffle/utils/raffleSelector";
import { useIsMobileView } from "../../utils/responsive";
import { PurchasersTable } from "../puchasers/table";
import WinnerSearchResults from "../raffle/winnerSearch";



function useDebounce(value: string, delay: number): string {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}


export const WinnersPage = () => {
  const [selectedRaffleId, setSelectedRaffleId] = useState<string>('');


  const { data: rafflesFounded, error: errorGetRaffles } = useGetRafflesQuery(undefined, {});

  const handleFindRaffleStatus = (e: SelectChangeEvent) => {
    const selectedValue = e.target.value;
    setSelectedRaffleId(selectedValue);
  };

  const { data: purchasers, refetch } = useGetPurchasersQuery(selectedRaffleId, {
    skip: selectedRaffleId === '',
  });

  useEffect(() => {
    if (selectedRaffleId !== "") {
      refetch();
    }
  }, [selectedRaffleId, refetch])
  

  const [open, setOpen] = useState(false);

    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    const handleClose = () => {
      setOpen(false);
    };
   
  const isMobileView = useIsMobileView();

  return (
    <Box paddingX={isMobileView ? 2 : 10} marginTop={2}>
      <Typography variant="h6" fontFamily={"Georgia"} paddingBottom={2} >
        Ganadores
      </Typography>
      {rafflesFounded && !errorGetRaffles && rafflesFounded.length > 0 && (
        <RaffleSelector
          handleSelectRaffle={handleFindRaffleStatus}
          rafflesFounded={rafflesFounded}
          selectedRaffleId={selectedRaffleId}
          setSelectedRaffleId={setSelectedRaffleId}
        />
      )}


    <Box m={10}>

    <TextField
              InputProps={{ inputProps: { min: 0, max: 10000 } }}
              id="ticket"
              label="Número ganador"
              type="number"
              fullWidth
              variant="standard"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />

      {purchasers && selectedRaffleId ? <WinnerSearchResults setOpen={setOpen} raffleId={selectedRaffleId} ticket={Number.parseInt(debouncedSearchTerm)} /> : null}
    </Box>

    </Box>
  );
};
