import { useIsMobileView } from "../../utils/responsive"
import DesktopCreateStepperRaffle from "../raffle/create/desktopSteps";
import MobileCreateStepperRaffle from "../raffle/create/mobileSteps";
import { Alert, Box, CircularProgress, Typography } from "@mui/material";
import { Award, ICreationRaffle, InitialCreationRaffle, InitialCreationRaffleReset } from "../../store/interfaces/ICreationRaffle";
import { useCreateRaffleImageMutation, useCreateRaffleMutation, useGetActiveRaffleQuery } from "../../store/api";
import { useEffect, useState } from "react";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { mapICreationRaffleToDTO } from "../../store/api/utils/CreationRaffleMapper";
import { useNavigate } from "react-router-dom";
import { SnackbarKey, useSnackbar } from "notistack";
import { useAppSelector } from "../../store/hooks/useAppSelector";
import { RootState } from "../../store/types/rootState";


export const CreatePage = () => {

  const loggedInAccountId = useAppSelector(
      ({ account }: RootState) => account.id,
    );

    const navigate = useNavigate();
    const [creationRaffleResource, setCreationRaffleResource] = useState<ICreationRaffle>(InitialCreationRaffle);

    const [awards, setAwards] = useState<Award[]>([]);
    const [createRaffle, { isLoading }] = useCreateRaffleMutation();
    const [createRaffleImage] = useCreateRaffleImageMutation();
    const [disableNext, setDisableNext] = useState(false);

    useEffect(() => {
        setCreationRaffleResource(InitialCreationRaffle);
    }, [])
    

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleSnackbarClose = (key: SnackbarKey) => {
        closeSnackbar(key);
      };

      function showToast(message: string, variant: string) {
        if (variant !== "success" && variant !== "warning" && variant !== "error") {
          return;
        }
        enqueueSnackbar(message, {
          action: (key) => {
            return (
              <HighlightOffIcon
                sx={{ cursor: "pointer" }}
                onClick={() => handleSnackbarClose(key)}
              />
            );
          },
          variant: variant,
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
        });
      }

      const accountId = useAppSelector(
        ({ account }: RootState) => account.id,
      );

      const { data: activeRaffleFounded, refetch } = useGetActiveRaffleQuery(accountId, {
        skip: accountId === "default" || accountId === "",
      });
    
    const handleCreateRaffle = async (validatedRaffleResource: ICreationRaffle | undefined) => {

        if (validatedRaffleResource) {
          validatedRaffleResource.accountId = loggedInAccountId;
            try {
                const raffleCreationResponse = await createRaffle(mapICreationRaffleToDTO(validatedRaffleResource)).unwrap();
                if (raffleCreationResponse) {
                      validatedRaffleResource.awards.flatMap(element => element.images).forEach((image) => {
                        createRaffleImage({
                          accountId: loggedInAccountId,
                          imageFile: image,
                          imageName: image.name,
                          raffleId: raffleCreationResponse.id || "",
                        }).then((response) => {
                          showToast('Imagen creada', 'success'); 
                        }).catch((error) => {
                          showToast('Hubo un error al crear la imagen', 'error'); 
                        });
                      });
                      showToast('Rifa creada!', 'success');
                      setCreationRaffleResource(InitialCreationRaffleReset)
                      setAwards([])
                      navigate("/dashboard");
                } else {
                  showToast('No se pudo crear la rifa en este momento!', 'warning');
                }
            } catch (error: any) {
              if (error?.data?.errors && error?.data?.errors.length > 1) {
                showToast(error?.data?.errors[0], 'error');
              } else {
                showToast('Error al momento de crear la rifa!', 'error');
                showToast('No puede tener una rifa activa primero desactivela!', 'error');
              }
            }
        }
    }
    

    const isMobile = useIsMobileView();

    if (activeRaffleFounded) {
        return <Box>
          <Alert severity="error"> No se pueden crear rifas nuevas si tiene una rifa activa, desactivela y vuelva a intentar. </Alert>
        </Box>
    }

    return (
      <>
        <Typography mt={2} px={10} variant="h6">
          Formulario de creación
        </Typography>
        {isLoading ? (
          <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        ) : isMobile ? (
          <MobileCreateStepperRaffle
            awards={awards}
            setAwards={setAwards}
            handleCreateRaffle={handleCreateRaffle}
            setResource={setCreationRaffleResource}
            resource={creationRaffleResource}
            setDisableNext={setDisableNext}
            disableNext={disableNext}
          />
        ) : (
          <DesktopCreateStepperRaffle
            awards={awards}
            setAwards={setAwards}
            resource={creationRaffleResource}
            handleCreateRaffle={handleCreateRaffle}
            setResource={setCreationRaffleResource}
            setDisableNext={setDisableNext}
            disableNext={disableNext}
          />
        )}
      </>
    );
}
    