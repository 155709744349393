import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export const CircularProgressRaffle = (
  props: CircularProgressProps & { value: number; size?: number },
) => {

  const { size = 100, ...progressProps } = props;

  return (
        <Box sx={{ position: "relative", display: "inline-flex" }}>
          <CircularProgress
            variant="determinate"
            size={size}
            {...progressProps}
            sx={{ color: '#2031d8' }}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="caption"
              component="div"
              fontSize={20}
              color="text.secondary"
              fontWeight={"bold"}
            >{`${props.value}%`}</Typography>
          </Box>
        </Box>
  );
}
