import { IAccount } from "../interfaces/IAccount";



export const AccountStateDefault: IAccount = {
     id: "default",
     logo: "default",
     name: "default",
     primaryColor: "#000",
     secondaryColor: "#FFF",
     socialMediaList: [{
          name: "default",
          username: "default",
     }]
};
