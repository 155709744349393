import React from 'react';
import Carousel from 'react-material-ui-carousel';
import {
    Paper,
    Typography,
    List,
    ListItem,
    ListItemText,
    Stack,
    Box,
    Button,
} from '@mui/material';
import { PROMOTION_STEPS_CONFIG, PromoStepItem } from '../../constants/promotionSteps';

interface PromotionStepsProps {
    accountId: string;
}

const PromotionSteps = ({accountId}: PromotionStepsProps) => {
    const config = PROMOTION_STEPS_CONFIG.find(PromotionStep => PromotionStep.accountId === accountId);

    if (!config) {
        return <></>
    }

    return (
        <>
            <Typography variant='h4' fontWeight={"bold"}  textAlign={"center"}>
                SIGUE ESTOS PASOS PARA PARTICIPAR
            </Typography>
            <Box display={"flex"} justifyContent={"space-around"} flexDirection={"column"} width={"100%"} mt={2}>
                {config?.promoSteps.map((item, index) => {
                    return <PromotionStep item={item} url={config.publicationLink} key={index} />;
                })}
            </Box>
            {/* <Carousel autoPlay={false}>
                {items.map((item, index) => {
                    return <PromotionStep item={item} key={index} />;
                })}
            </Carousel> */}
            <br />
        </>
    );
}

interface PromotionStepProps {
    url: string;
    item: PromoStepItem;
}

function PromotionStep({ item, url }: PromotionStepProps) {
    const paperStyle = {
        padding: '10px',
        margin: '5px',
        width: '100%',
        borderRadius: '10px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
        marginBottom: '10px',
    };

    const handleGoToPublication = () => {
        window.open(url)
    }

    return (
        <Button onClick={handleGoToPublication} style={{ textAlign: 'center' }}>
            <Paper style={paperStyle} elevation={10}>
                <Typography textAlign={"center"} color={"primary"} variant='h5' fontWeight={"bold"}>{`${item.name}`}</Typography>
                <Box width={"100%"} display={"flex"} justifyContent={"center"}>
                    <Typography color={"primary"} variant='h5' fontWeight={"bold"}>{item.icon}</Typography>
                </Box>
            </Paper>
            </Button>
    )
}

export default PromotionSteps;
